<template>
  <transition name="toast-transition">
    <div
      v-if="globalMessages.messages.length"
      class="fixed z-[101] -translate-x-1/2 bottom-10 left-1/2 flex flex-col-reverse items-center"
    >
      <div
        v-for="message in globalMessages.messages"
        :key="message.id"
        class="lf-toast"
        data-cy="toast-message"
      >
        <div v-if="message.type === 'loading'" class="w-6">
          <loader
            is-loading
            spinner-type="spinner"
            duration="0.9"
            color="#3769D6"
            size="30"
          />
        </div>
        <div v-else-if="message.icon">
          <icon-base
            :class="[
              getIconClassName(message),
              { 'tranform translate-y-0-5': message.type === 'error' }
            ]"
            v-tooltip="message.subTitle"
            v-bind="getIconAttrs(message.type)"
            :icon="message.icon"
          />
        </div>
        <div class="lf-toast-message" data-testid="globalMessageTitle">
          {{ message.title }}
        </div>
        <div
          v-if="
            message.type !== 'loading' && (message.canClose || message.action)
          "
          class="lf-toast-actions"
        >
          <a
            v-if="message.action"
            class="font-semibold cursor-pointer text-primary hover:text-primary-link-hover"
            @click.prevent="handleAction(message)"
          >
            {{ message.action.label }}
          </a>
          <span v-if="message.canClose">
            <icon-base
              class="close-alert-icon"
              :icon="IconClear"
              @click="message.destroy()"
            />
          </span>
        </div>
      </div>
    </div>
  </transition>
</template>
<script setup lang="ts">
import { type Toast, type ToastMessage } from "@/lib/Toast";
import { computed } from "vue";
import { useStore } from "vuex";
import IconClear from "@/components/icons/IconClear.vue";

const { getters } = useStore();

const globalMessages = computed<InstanceType<typeof Toast>>(
  () => getters["globalMessages"]
);

const getIconClassName = (message: ToastMessage) => {
  if (["error", "delete"].includes(message.type)) {
    return "text-red-400";
  }
  if (["success", "put", "post", "patch"].includes(message.type)) {
    return "text-green-400";
  }
  return "";
};

const getIconAttrs = (type: ToastMessage["type"]) => {
  switch (type) {
    case "error":
      return { width: 18, height: 18, viewBox: "0 0 13 13" };
    case "copy":
      return { width: 16, height: 16, viewBox: "2 2 16 16" };

    default:
      return {};
  }
};

const handleAction = (message: ToastMessage) => {
  message.action?.handler();
  message.destroy();
};
</script>

<style scoped>
.lf-toast {
  max-width: 90vw;
  @apply bg-black w-fit-content items-center text-white flex justify-between py-2 px-4 space-x-4 shadow-xl rounded mt-4 overflow-x-hidden;
}

.lf-toast-message,
.lf-toast-actions {
  @apply py-2;
}

.lf-toast-message {
  width: max-content;
  white-space: pre;
}

.lf-toast-actions {
  border-left: 1px solid rgba(255 255 255 / 0.2);
  @apply border-l pl-4 w-auto flex-shrink-0 self-stretch flex space-x-4 items-center;
}

.close-alert-icon {
  cursor: pointer;
  vertical-align: middle;
  margin-bottom: 0;
}

.toast-transition-enter-active {
  transition: all 0.2s ease-out;
}

.toast-transition-leave-active {
  transition: all 0.2s ease-in;
}

.toast-transition-enter-from,
.toast-transition-leave-to {
  @apply translate-y-32 opacity-0;
}
</style>
